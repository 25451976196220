var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-page-heading',{attrs:{"title":"Appear","subtitle":"Animate elements and make them visible on scrolling."},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('b-breadcrumb',{staticClass:"breadcrumb-alt"},[_c('b-breadcrumb-item',{attrs:{"href":"javascript:void(0)"}},[_vm._v("Plugins")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("Appear")])],1)]},proxy:true}])}),_c('div',{staticClass:"content"},[_c('h2',{staticClass:"content-heading"},[_vm._v("Basic")]),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementBasic1),expression:"handleVisibilityElementBasic1",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementBasic1,
            'animated fadeIn': _vm.isVisibleElementBasic1,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-primary text-white mx-auto"},[_c('i',{staticClass:"fa fa-2x fa-brush"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Create")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementBasic2),expression:"handleVisibilityElementBasic2",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementBasic2,
            'animated fadeIn': _vm.isVisibleElementBasic2,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-success text-white mx-auto"},[_c('i',{staticClass:"fab fa-2x fa-bitcoin"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Bitcoin")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementBasic3),expression:"handleVisibilityElementBasic3",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementBasic3,
            'animated fadeIn': _vm.isVisibleElementBasic3,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-dark text-white mx-auto"},[_c('i',{staticClass:"fab fa-2x fa-chrome"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Browser")])])])],1)],1),_vm._m(0),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementAnimation1),expression:"handleVisibilityElementAnimation1",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementAnimation1,
            'animated bounceIn': _vm.isVisibleElementAnimation1,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-warning text-white mx-auto"},[_c('i',{staticClass:"si fa-2x si-user"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Users")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementAnimation2),expression:"handleVisibilityElementAnimation2",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementAnimation2,
            'animated flipInX': _vm.isVisibleElementAnimation2,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-danger text-white mx-auto"},[_c('i',{staticClass:"si fa-2x si-settings"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Settings")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementAnimation3),expression:"handleVisibilityElementAnimation3",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementAnimation3,
            'animated flash': _vm.isVisibleElementAnimation3,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-info text-white mx-auto"},[_c('i',{staticClass:"si fa-2x fa-2x si-rocket"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Startup")])])])],1)],1),_vm._m(1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once.50",value:(_vm.handleVisibilityElementOffset1),expression:"handleVisibilityElementOffset1",modifiers:{"50":true,"once":true}}],class:{
            'invisible': !_vm.isVisibleElementOffset1,
            'animated fadeIn': _vm.isVisibleElementOffset1,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-danger-light text-danger mx-auto"},[_c('i',{staticClass:"si fa-2x si-lock"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Lock")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once.100",value:(_vm.handleVisibilityElementOffset2),expression:"handleVisibilityElementOffset2",modifiers:{"100":true,"once":true}}],class:{
            'invisible': !_vm.isVisibleElementOffset2,
            'animated fadeIn': _vm.isVisibleElementOffset2,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-warning-light text-warning mx-auto"},[_c('i',{staticClass:"si fa-2x si-energy"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Energy")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once.150",value:(_vm.handleVisibilityElementOffset3),expression:"handleVisibilityElementOffset3",modifiers:{"150":true,"once":true}}],class:{
            'invisible': !_vm.isVisibleElementOffset3,
            'animated fadeIn': _vm.isVisibleElementOffset3,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-info-light text-info mx-auto"},[_c('i',{staticClass:"si fa-2x si-calendar"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Events")])])])],1)],1),_vm._m(2),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementTimeout1),expression:"handleVisibilityElementTimeout1",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementTimeout1,
            'animated fadeIn': _vm.isVisibleElementTimeout1,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-body text-primary mx-auto"},[_c('i',{staticClass:"si fa-2x si-speedometer"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Dashboard")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementTimeout2),expression:"handleVisibilityElementTimeout2",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementTimeout2,
            'animated fadeIn': _vm.isVisibleElementTimeout2,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-body text-danger mx-auto"},[_c('i',{staticClass:"si fa-2x si-printer"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Print")])])])],1),_c('b-col',{attrs:{"sm":"4"}},[_c('base-block',{directives:[{name:"b-visible",rawName:"v-b-visible.once",value:(_vm.handleVisibilityElementTimeout3),expression:"handleVisibilityElementTimeout3",modifiers:{"once":true}}],class:{
            'invisible': !_vm.isVisibleElementTimeout3,
            'animated fadeIn': _vm.isVisibleElementTimeout3,
          },attrs:{"rounded":"","content-full":""}},[_c('div',{staticClass:"py-5 text-center"},[_c('div',{staticClass:"item item-2x item-rounded bg-body text-success mx-auto"},[_c('i',{staticClass:"si fa-2x si-envelope-open"})]),_c('div',{staticClass:"font-size-h4 font-w600 pt-3 mb-0"},[_vm._v("Messages")])])])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"content-heading"},[_vm._v("Animated "),_c('small',[_vm._v("You can use the animation classes of your choice")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"content-heading"},[_vm._v("Offset "),_c('small',[_vm._v("You can add an offset, to make the element appear sooner")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"content-heading"},[_vm._v("Timeouts "),_c('small',[_vm._v("You can delay the animation, creating nice effects")])])}]

export { render, staticRenderFns }